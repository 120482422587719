import React from 'react';
import { useSelector } from 'react-redux';
import { PayFrequencyOptionValue } from 'handlers/yourTotalIncome';
import { getApplicationData } from 'selectors/getApplicationData';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { getPreQualificationData } from 'selectors/preQualificationData';

export enum PartnerId {
  Flasha = 'flasha',
  Fpma = 'fpma',
  Hillcrest = 'hillcrest',
  SunyDownstate = 'suny-downstate',
  PhoebePutney = 'phoebe-putney',
  DelawareNursesAssociation = 'dna',
  UNCHealthLenoir = 'unc-health-lenoir',
  Nursegrid = 'nursegrid',
}

export enum PartnerType {
  Employer = 'Employer',
  Association = 'Association',
  App = 'App',
}

export interface PartnerData {
  fullName?: string;
  shortName?: string;
  type?: PartnerType;
  logoExtension?: 'png' | 'svg';
  matchesEmployer?: (employerName: string) => boolean;
  supportsStudentLoanApplicationPayrollDeduction?: boolean;
  supportsStudentLoanPaymentViaCardIn12Installments?: boolean;
  downloadPayrollInstructions?: JSX.Element;
  directDepositInstructions?: JSX.Element;
  benefitEligibleTitles?: string[];
  debtConsolidationEnabled?: boolean;
  paymentFrequency?: PayFrequencyOptionValue;
}

export const PARTNER_DATA: Record<PartnerId, PartnerData> = Object.freeze({
  [PartnerId.SunyDownstate]: {
    fullName: 'SUNY Downstate Health Sciences University',
    shortName: 'SUNY Downstate',
    type: PartnerType.Employer,
    matchesEmployer: (employerName: string) =>
      employerName.indexOf('downstate') >= 0 || employerName.indexOf('university physicians of brooklyn') >= 0,
    paymentFrequency: PayFrequencyOptionValue.BiWeekly,
    downloadPayrollInstructions: (
      <>
        <ol>
          <li>
            Click{' '}
            <a
              href="https://idm.suny.edu/security/login/loginForm.do?redirectUrl=https://www2.sysadm.suny.edu/EmployeeServices/Main/employeeportal.cfm"
              target="_blank"
              rel="noreferrer"
            >
              the link
            </a>{' '}
            to login to your HR system.
          </li>
          <li>Select Downstate Medical campus, then Human Resource Systems, then NYS Payroll Online.</li>
          <li>Once logged in, click View W-2 or View Paycheck.</li>
          <li>
            If you need more assistance logging in, see{' '}
            <a
              href="https://www.downstate.edu/about/our-administration/human-resources/self-service.html"
              target="_blank"
              rel="noreferrer"
            >
              full instructions
            </a>
            .
          </li>
        </ol>
      </>
    ),
  },
  [PartnerId.PhoebePutney]: {
    fullName: 'Phoebe Putney',
    shortName: 'Phoebe',
    type: PartnerType.Employer,
    matchesEmployer: (employerName: string) => employerName.indexOf('phoebe') >= 0,
    logoExtension: 'svg',
    paymentFrequency: PayFrequencyOptionValue.BiWeekly,
  },
  [PartnerId.Hillcrest]: {
    fullName: 'Hillcrest Family Services',
    shortName: 'Hillcrest',
    type: PartnerType.Employer,
  },
  [PartnerId.Flasha]: {
    fullName: 'Florida Association of Speech-Language Pathologists and Audiologists',
    shortName: 'FLASHA',
    type: PartnerType.Association,
  },
  [PartnerId.Fpma]: {
    fullName: 'Florida Pediatric Medical Association',
    shortName: 'FPMA',
    type: PartnerType.Association,
  },
  [PartnerId.DelawareNursesAssociation]: {
    fullName: 'Delaware Nurses Association',
    shortName: 'DNA',
    type: PartnerType.Association,
  },
  [PartnerId.UNCHealthLenoir]: {
    fullName: 'UNC Health Lenoir',
    shortName: 'UNC',
    type: PartnerType.Employer,
    matchesEmployer: (employerName: string) => employerName.indexOf('lenoir') >= 0,
    paymentFrequency: PayFrequencyOptionValue.BiWeekly, // TODO: Confirm
    directDepositInstructions: (
      <>
        <ol style={{ textAlign: 'left' }}>
          <li>
            Click{' '}
            <a
              href="https://mingle-portal.inforcloudsuite.com/UNCHEALTHUNC_PRD/89835747-8537-4269-aff9-6d0eb8eeb3c5"
              target="_blank"
              rel="noreferrer"
            >
              the link
            </a>{' '}
            to login to your HR system.
          </li>
          <li>
            Follow{' '}
            <a
              href="http://assets.planneryapp.com/media/partners/unc-health-lenoir/direct-deposit-instructions.pdf"
              target="_blank"
              rel="noreferrer"
            >
              the instructions
            </a>{' '}
            to add a direct deposit destination.
          </li>
          <li>After you finish, you’ll upload a screenshot to verify this has been done.</li>
        </ol>
      </>
    ),
  },
  [PartnerId.Nursegrid]: {
    fullName: 'Nursegrid',
    shortName: 'Nursegrid',
    type: PartnerType.App,
    logoExtension: 'svg',
  },
});

export const getPartnerName = (partnerId: PartnerId) => {
  const index = Object.values(PartnerId).indexOf(partnerId);
  if (index === -1) {
    return partnerId
      .split('-')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
      .join(' ');
  }
  const key = Object.keys(PartnerId)[index];
  return key.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3');
};

export const getPartnerFromEmployerName = (employerName?: string): PartnerId | undefined => {
  const employerNameLowerCase = (employerName ?? '').toLowerCase();
  return Object.values(PartnerId).find((partnerName) =>
    PARTNER_DATA[partnerName].matchesEmployer?.(employerNameLowerCase),
  );
};

export const getPartnerFromEmployerFullName = (employerName: string): PartnerId | undefined =>
  Object.values(PartnerId).find((partnerId) => PARTNER_DATA[partnerId].fullName === employerName);

export const usePartnerData = (): PartnerData & { partnerName?: PartnerId } => {
  const { application } = useSelector(getApplicationData);
  let { partnerId: partnerName } = useSelector(getPreQualificationData);

  const {
    applicationData: { employerName: studentLoanEmployerName },
  } = useSelector(getStudentLoanApplication);

  const employerName = application?.employment.employer1.employerName ?? studentLoanEmployerName;
  partnerName = getPartnerFromEmployerName(employerName) ?? partnerName;

  const partnerData = (partnerName ? PARTNER_DATA[partnerName] : null) ?? {};

  return { ...partnerData, partnerName };
};
