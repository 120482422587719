import { PARTNER_DATA, PartnerData, PartnerId, PartnerType } from 'enums/PartnerName';

import { calculateLevenshteinDistance } from './calculateLevenshteinDistance';

enum DistanceLimits {
  FullNameDistanceLimit = 2,
  ShortNameDistanceLimit = 3,
}

interface EmployerMatchesData {
  fullName: PartnerData['fullName'];
  ignoredWords: number;
}

interface EmployerShortNameDistanceData {
  fullName: PartnerData['fullName'];
  levenshteinDistance: number;
}

const irrelevantWords = ['the', 'of', 'and', 'a', 'an', 'in', 'on', 'at', 'to', 'for', 'with', 'by', 'from'];

const cleanAndSplitString = (str: string): string[] => {
  let cleanedStr = str.toLowerCase();
  cleanedStr = cleanedStr.replace(/[.,']/g, '');

  const wordsArray = cleanedStr.split(' ');
  return wordsArray.filter((word) => !irrelevantWords.includes(word));
};

const matchEmployerWords = (employerFullNameWords: string[], userEmployerNameWords: string[]): string[] => {
  const matchingWords: string[] = [];

  userEmployerNameWords.forEach((employerWord) => {
    let closestMatch = '';

    employerFullNameWords.forEach((nameWord) => {
      const levenshteinDistance = calculateLevenshteinDistance(nameWord, employerWord);

      if (levenshteinDistance <= DistanceLimits.FullNameDistanceLimit) {
        closestMatch = nameWord;
      }
    });

    if (closestMatch && !matchingWords.includes(closestMatch)) {
      matchingWords.push(closestMatch);
    }
  });

  return matchingWords;
};

export const getEmployerMatches = (employerName: string): EmployerMatchesData[] => {
  const partnerIdList = Object.values(PartnerId);

  const partnerDistances = partnerIdList.map((partnerId) => {
    const partner = PARTNER_DATA[partnerId];

    if (partner.type === PartnerType.Employer) {
      const userEmployerNameWords = cleanAndSplitString(employerName);
      const employerFullNameWords = cleanAndSplitString(partner.fullName ?? '');

      const matchingFullNameWords = matchEmployerWords(employerFullNameWords, userEmployerNameWords);
      const nonMatchingWordsCount = employerFullNameWords.length - matchingFullNameWords.length;

      if (nonMatchingWordsCount > 0) {
        const allowedNonMatchingWords = Math.round(nonMatchingWordsCount / 2);

        if (nonMatchingWordsCount <= allowedNonMatchingWords) {
          return { fullName: partner.fullName, ignoredWords: nonMatchingWordsCount };
        }
      } else if (nonMatchingWordsCount === 0) {
        return { partnerId, fullName: partner.fullName, ignoredWords: nonMatchingWordsCount };
      }
    }

    return null;
  });

  return partnerDistances.filter((distance): distance is EmployerMatchesData => distance !== null);
};

const calculateEmployerShortNameDistance = (employerName: string): EmployerShortNameDistanceData[] => {
  const employerNameLowerCase = employerName.toLowerCase();
  const partnerIdList = Object.values(PartnerId);

  const partnerDistances = partnerIdList.map((partnerId) => {
    const partner = PARTNER_DATA[partnerId];

    if (partner.type === PartnerType.Employer) {
      const shortName = partner.shortName?.toLowerCase() ?? '';

      return {
        fullName: partner.fullName,
        levenshteinDistance: calculateLevenshteinDistance(shortName, employerNameLowerCase),
      };
    }

    return null;
  });

  return partnerDistances.filter((distance): distance is EmployerShortNameDistanceData => distance !== null);
};

export const getMatchingEmployerName = (employerName: string): PartnerData['fullName'] => {
  const employerMatches = getEmployerMatches(employerName);
  const sortedEmployers = employerMatches ? employerMatches.sort((a, b) => a.ignoredWords - b.ignoredWords) : [];
  const closestFullNameMatch = sortedEmployers[0];

  if (!closestFullNameMatch) {
    const sortedShortNameDistances = calculateEmployerShortNameDistance(employerName).sort(
      (a, b) => a.levenshteinDistance - b.levenshteinDistance,
    );
    const closestShortNameMatch = sortedShortNameDistances[0];
    const isValidClosestMatch = closestShortNameMatch.levenshteinDistance < DistanceLimits.ShortNameDistanceLimit;

    return isValidClosestMatch ? closestShortNameMatch.fullName : undefined;
  }

  return closestFullNameMatch.fullName;
};
