import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import Button from 'components/Button';
import CheckboxSmall from 'components/Checkbox/CheckboxSmall';
import DatePicker from 'components/DatePicker/DatePicker';
import FormContainer from 'components/LoanForm/FormContainer';
import FormNavigation from 'components/FormNavigation';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import NumberInput from 'components/NumberInput/NumberInput';
import EmployerModal from 'components/StudentLoanForgiveness/Modal/EmployerModal';

import { CurrentFlow } from 'enums/CurrentFlow';
import {
  getPartnerFromEmployerFullName,
  getPartnerFromEmployerName,
  PARTNER_DATA,
  PartnerId,
  PartnerType,
} from 'enums/PartnerName';
import { YourIncomeVariable } from 'enums/LoanFormVariables';

import { PreQualificationData, setPartnerName } from 'handlers/preQualificationData';
import { setYourIncomeData } from 'handlers/yourIncome';
import { PayFrequencyOptionValue } from 'handlers/yourTotalIncome';

import { FlowComponentType } from 'routes/types';

import { getApplicationStep } from 'selectors/getApplicationStep';
import { getPreQualificationData } from 'selectors/preQualificationData';
import { getYourIncome } from 'selectors/yourIncome';

import { formatDate, getYearsFromNow } from 'utils/dateUtils';
import { getMessageForRequiredFields } from 'utils/errors';
import { getMatchingEmployerName } from 'utils/getMatchingPartnerName';

import {
  ANNUAL_INCOME_MAX_LENGTH,
  PAY_FREQUENCY_OPTIONS,
} from 'components/Verification/Steps/EmploymentDetails/EmploymentDetails';

import styles from './YourIncome.module.scss';

enum YourIncomeInputLabel {
  GrossAnnualIncome = 'Gross annual income',
  StartOfEmployment = 'Start date of primary employer',
  EmployerName = 'Primary employer name',
  JobTitle = 'Job title',
  PayFrequency = 'Pay frequency',
  NotCurrentlyEmployed = 'Not currently employed',
}

const YourIncome = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();
  const state = useLocation().state as { fromCardFlow?: boolean };
  const isCardFlow = state?.fromCardFlow;
  const { currentFlow } = useSelector(getApplicationStep);
  const { partnerId: partnerName, benefit } = useSelector(getPreQualificationData);
  const [showEmployerModal, setShowEmployerModal] = useState(false);
  const [matchingEmployerName, setMatchingEmployerName] = useState('');
  const defaultValues = useSelector(getYourIncome);

  const {
    register,
    watch,
    setValue,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      ...(partnerName &&
        PARTNER_DATA[partnerName].type === PartnerType.Employer && {
          employer_name: PARTNER_DATA[partnerName].fullName,
        }),
    },
  });

  const watcher = watch();

  const initialPartner = benefit && partnerName ? PARTNER_DATA[partnerName] : null;
  const benefitEligibleTitles = initialPartner?.benefitEligibleTitles ?? [];

  const shouldCollectJobTitle = benefitEligibleTitles.length > 0;

  const updatePartnerName = (name: PartnerId) => {
    const preQualificationData: PreQualificationData = {
      partnerId: name,
    };
    dispatch(setPartnerName(preQualificationData));
  };

  useEffect(() => {
    if (initialPartner && !!initialPartner.paymentFrequency) {
      setValue(YourIncomeVariable.PayFrequency, initialPartner.paymentFrequency);
    }
  }, [initialPartner]);

  const handleContinue = async () => {
    const updatedValues = getValues();

    dispatch(setYourIncomeData(updatedValues));
    handleNext();
  };

  const checkForPartner = (employerName: string) => {
    const bestMatchedEmployer = getMatchingEmployerName(employerName);

    if (bestMatchedEmployer) {
      setMatchingEmployerName(bestMatchedEmployer);

      if (bestMatchedEmployer.toLowerCase() === employerName.toLowerCase()) {
        handleUpdateEmployerName(bestMatchedEmployer);
      } else {
        setShowEmployerModal(true);
      }
    } else {
      const partner = getPartnerFromEmployerName(employerName);

      if (partner) updatePartnerName(partner);
      handleContinue();
    }
  };

  const handleUpdateEmployerName = (exactEmployerName?: string) => {
    const currentEmployerName = exactEmployerName ?? matchingEmployerName;
    const partner = getPartnerFromEmployerFullName(currentEmployerName);

    setValue(YourIncomeVariable.EmployerName, currentEmployerName);
    trigger(YourIncomeVariable.EmployerName);

    if (partner) updatePartnerName(partner);
    if (!exactEmployerName) setShowEmployerModal(false);

    handleContinue();
  };

  const handleApplyButton = () => {
    checkForPartner(watcher[YourIncomeVariable.EmployerName] as string);
  };

  const validateEmploymentFields = (errorMessage: string) => (value: any) =>
    watch()[YourIncomeVariable.NotEmployed] || (value !== null && value !== undefined && value !== '')
      ? true
      : errorMessage;

  useEffect(() => {
    register(YourIncomeVariable.TotalAnnualIncome, {
      required: getMessageForRequiredFields(YourIncomeInputLabel.GrossAnnualIncome),
    });
    register(YourIncomeVariable.StartOfEmployment, {
      validate: validateEmploymentFields(getMessageForRequiredFields(YourIncomeInputLabel.StartOfEmployment)),
    });
    register(YourIncomeVariable.EmployerName, {
      validate: validateEmploymentFields(getMessageForRequiredFields(YourIncomeInputLabel.EmployerName)),
    });
    register(YourIncomeVariable.PayFrequency, {
      validate: validateEmploymentFields(getMessageForRequiredFields(YourIncomeInputLabel.PayFrequency)),
    });
    register(YourIncomeVariable.NotEmployed);
    if (shouldCollectJobTitle) {
      register(YourIncomeVariable.JobTitle, {
        validate: validateEmploymentFields(getMessageForRequiredFields(YourIncomeInputLabel.JobTitle)),
      });
    }
  }, [register]);

  let title = '';
  let subtitle = '';

  switch (currentFlow) {
    case CurrentFlow.Card:
    case CurrentFlow.DebtConsolidation:
      title = 'What’s your income?';
      subtitle = 'This information is needed to put together your offer.';
      break;
    default:
      title = 'What’s your income?';
      subtitle = 'This will help us calculate your results.';
      break;
  }

  return (
    <>
      <FormNavigation {...navigationInfo} {...(isCardFlow && { step: 2, stepCount: 2 })} />
      <FormContainer title={title} subtitle={subtitle}>
        <NumberInput
          label={YourIncomeInputLabel.GrossAnnualIncome}
          inputMode="numeric"
          prefix="$"
          placeholder="$0"
          errorMessage={errors[YourIncomeVariable.TotalAnnualIncome]?.message}
          thousandSeparator
          name={YourIncomeVariable.TotalAnnualIncome}
          onChange={(event) => {
            setValue(YourIncomeVariable.TotalAnnualIncome, Number(event.target.value.replace(/[^0-9.-]+/g, '')));
            trigger(YourIncomeVariable.TotalAnnualIncome);
          }}
          value={
            watcher[YourIncomeVariable.TotalAnnualIncome]
              ? `${watcher[YourIncomeVariable.TotalAnnualIncome]}`
              : undefined
          }
          maxLength={ANNUAL_INCOME_MAX_LENGTH}
          onKeyUp={(e) => e.key === 'Enter' && isValid && handleApplyButton()}
          autoFocus
        />
        <div className={styles.note}>
          Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it
          considered as a basis for repaying this obligation.
        </div>
        <Input
          label={YourIncomeInputLabel.EmployerName}
          className={styles.formInput}
          onChange={(event) => {
            setValue(YourIncomeVariable.EmployerName, event.target.value);
            trigger(YourIncomeVariable.EmployerName);
          }}
          onBlur={(event) => {
            setValue(YourIncomeVariable.EmployerName, event.target.value.trim());
            trigger(YourIncomeVariable.EmployerName);
          }}
          disabled={watcher[YourIncomeVariable.NotEmployed]}
          placeholder="Employer name"
          value={watcher[YourIncomeVariable.EmployerName]}
          errorMessage={errors[YourIncomeVariable.EmployerName]?.message}
          onKeyUp={(e) => e.key === 'Enter' && isValid && handleApplyButton()}
        />
        <CheckboxSmall
          label={YourIncomeInputLabel.NotCurrentlyEmployed}
          name={YourIncomeVariable.NotEmployed}
          onChange={(event) => {
            setValue(YourIncomeVariable.NotEmployed, event.target.checked);
            // Reset the rest of the fields
            setValue(YourIncomeVariable.EmployerName, '');
            setValue(
              YourIncomeVariable.PayFrequency,
              event.target.checked
                ? PayFrequencyOptionValue.Monthly
                : initialPartner?.paymentFrequency ?? PayFrequencyOptionValue.BiWeekly,
            );
            setValue(YourIncomeVariable.StartOfEmployment, '');
            trigger([
              YourIncomeVariable.NotEmployed,
              YourIncomeVariable.EmployerName,
              YourIncomeVariable.PayFrequency,
              YourIncomeVariable.StartOfEmployment,
              ...(shouldCollectJobTitle ? [YourIncomeVariable.JobTitle] : []),
            ]);
          }}
          checked={watcher[YourIncomeVariable.NotEmployed]}
        />
        {watcher[YourIncomeVariable.EmployerName]!.length > 0 && (
          <InputSelect
            label={YourIncomeInputLabel.PayFrequency}
            options={PAY_FREQUENCY_OPTIONS}
            onChange={(option) => {
              setValue(YourIncomeVariable.PayFrequency, option.value);
              trigger(YourIncomeVariable.PayFrequency);
            }}
            placeholder="Pay Frequency"
            className={styles.formInput}
            name={YourIncomeVariable.PayFrequency}
            value={watcher[YourIncomeVariable.PayFrequency]}
            errorMessage={errors[YourIncomeVariable.PayFrequency]?.message}
          />
        )}
        {watcher[YourIncomeVariable.EmployerName]!.length > 0 && (
          <DatePicker
            maxDate={getYearsFromNow(0)}
            minDate={getYearsFromNow(-30)}
            placeHolder="Month YYYY"
            label={YourIncomeInputLabel.StartOfEmployment}
            className={styles.formInput}
            showMonthYearPicker
            selected={
              watcher[YourIncomeVariable.StartOfEmployment]
                ? new Date(watcher[YourIncomeVariable.StartOfEmployment] as string)
                : undefined
            }
            onChange={(date) => {
              setValue(YourIncomeVariable.StartOfEmployment, formatDate(date));
              trigger(YourIncomeVariable.StartOfEmployment);
            }}
            errorMessage={errors[YourIncomeVariable.StartOfEmployment]?.message}
            name={YourIncomeVariable.StartOfEmployment}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && isValid && handleContinue()}
          />
        )}
        {shouldCollectJobTitle && !watcher[YourIncomeVariable.NotEmployed] && (
          <InputSelect
            onChange={(option) => {
              setValue(YourIncomeVariable.JobTitle, option.value);
              trigger(YourIncomeVariable.JobTitle);
            }}
            className={styles.inputContainer}
            placeholder="Select"
            value={watcher[YourIncomeVariable.JobTitle]}
            label={YourIncomeInputLabel.JobTitle}
            onBlur={(event) => {
              setValue(event.target.name as YourIncomeVariable, event.target.value);
              trigger(event.target.name as YourIncomeVariable);
            }}
            options={[
              ...benefitEligibleTitles.map((item) => ({ label: item, value: item })),
              { label: 'Other', value: 'Other' },
            ]}
          />
        )}
        {showEmployerModal && (
          <EmployerModal
            onClose={() => setShowEmployerModal(false)}
            onKeepEmployer={handleContinue}
            onUpdateEmployer={() => handleUpdateEmployerName()}
            typedValue={watcher[YourIncomeVariable.EmployerName] ?? ''}
            matchingEmployerName={matchingEmployerName}
          />
        )}

        {isCardFlow && (
          <span className={styles.yourIncomeDisclaimer}>
            By clicking Apply, I hereby consent to the{' '}
            <a href="https://www.planneryapp.com/esign-agreement" target="_blank" rel="noreferrer">
              E-Sign Agreement
            </a>
            ,{' '}
            <a href="https://www.planneryapp.com/terms-of-service" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
            , and{' '}
            <a href="https://www.planneryapp.com/communication-policy" target="_blank" rel="noreferrer">
              Communication Policy
            </a>{' '}
            and I am providing written consent under the FCRA for Plannery to obtain consumer report information from my
            credit profile.
          </span>
        )}
        <Button className={styles.button} disabled={!isValid} onClick={handleApplyButton}>
          Apply
        </Button>
      </FormContainer>
    </>
  );
};

export default YourIncome;
