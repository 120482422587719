import React from 'react';
import Modal from 'components/Common/Modal';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';

import styles from './EmployerModal.module.scss';

type EmployerModalProps = {
  onClose: () => void;
  onKeepEmployer: () => void;
  onUpdateEmployer: () => void;
  typedValue: string;
  matchingEmployerName: string;
};

const EmployerModal = ({
  onClose,
  onKeepEmployer,
  onUpdateEmployer,
  typedValue,
  matchingEmployerName,
}: EmployerModalProps) => (
  <Modal isCenteredModal closeModal={onClose}>
    <div className={styles.mainContainer}>
      <p className={styles.title}>Employer Found</p>

      <div className={styles.labelContainer}>
        <p>You typed “{typedValue}”</p>
        <p>
          Is your employer “<strong>{matchingEmployerName}</strong>”?
        </p>
      </div>

      <div className={styles.buttonsContainer}>
        <Button onClick={onUpdateEmployer}>Update Employer</Button>
        <Button type={ButtonType.Inverted} onClick={onKeepEmployer}>
          Keep as is
        </Button>
      </div>
    </div>
  </Modal>
);

export default EmployerModal;
