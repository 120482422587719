import React, { useEffect } from 'react';
import clsx from 'clsx';

import { ReactComponent as CloseIcon } from 'images/cross-icon.svg';

import styles from './Modal.module.scss';

interface ModalProps {
  children: JSX.Element;
  title?: string;
  closeModal: () => void;
  isCenteredModal?: boolean;
}

const Modal = ({ children, closeModal, title, isCenteredModal }: ModalProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
      <div className={clsx(styles.container, isCenteredModal && styles.centeredModal)}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <CloseIcon onClick={closeModal} className={styles.closeIcon} />
        {children}
      </div>

      <div className={styles.backdrop} />
    </>
  );
};

export default Modal;
