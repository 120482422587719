export const calculateLevenshteinDistance = (sourceStr: string, targetStr: string): number => {
  const matrix = Array.from({ length: sourceStr.length + 1 }).map(() =>
    Array.from({ length: targetStr.length + 1 }).map(() => 0),
  );

  for (let i = 0; i <= sourceStr.length; i += 1) matrix[i][0] = i;
  for (let j = 0; j <= targetStr.length; j += 1) matrix[0][j] = j;

  for (let i = 1; i <= sourceStr.length; i += 1) {
    for (let j = 1; j <= targetStr.length; j += 1) {
      const cost = sourceStr[i - 1] === targetStr[j - 1] ? 0 : 1;
      matrix[i][j] = Math.min(matrix[i - 1][j] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j - 1] + cost);
    }
  }

  const totalDistance = matrix[sourceStr.length][targetStr.length];

  return totalDistance;
};
